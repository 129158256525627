import styled from 'styled-components';
import { GlobalStyles, cores } from '../globalStyles.js';
import background from '../assets/background.png'

export const HeaderContainer = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 4rem;
    background-color: ${cores.primaryBlue};
    color: ${cores.white};

    img {
        width: 8rem;
    }

    @media (max-width: 720px) {
        padding: 1rem 2rem;

        align-items: center;

        img {
            width: 6rem; 
        }
    }
`;

export const Nav = styled.nav`
    ul {
        list-style: none;
        display: flex;
    }

    li {
        margin: 0 15px;
    }

    a {
        color: ${cores.white};
        text-decoration: none;
        &:hover {
        text-decoration: underline;
        }
    }

    @media (max-width: 720px) {
        display: ${({ open }) => (open ? 'block' : 'none')}; 
        ul {
            flex-direction: column;
            align-items: center;
            padding: 0;
        }

        li {
            margin: 10px 0;
        }

        position: absolute;
        top: 80px; 
        left: 0;
        right: 0;
        background-color: ${cores.primaryBlue};
        padding: 1rem;
        box-shadow: 0 8px 16px rgba(0,0,0,0.6);
        border-radius: 8px;
    }
`;

export const ButtonPrimary = styled.button`
    padding: 1rem 1.5rem;
    border-radius: 2rem;
    border: none;
    font-weight: bold;
    color: ${cores.primaryBlue};

    a {
        color: ${cores.primaryBlue};
        text-decoration: none;
    }
`;

export const ButtonPrimaryDark = styled.button`
    padding: 1rem 1.5rem;
    border-radius: 2rem;
    border: none;
    font-weight: bold;
    background-color: ${cores.primaryBlue};
    color: ${cores.white};

    a {
        color: ${cores.white};
        text-decoration: none;
    }

    @media (max-width: 720px) {
        padding: 0.8rem 1.2rem;
        width: 40%;
        max-width: 300px;
    }
`;

export const HamburgerIcon = styled.div`
    display: none;

    @media (max-width: 720px) {
        display: block;
        cursor: pointer;
        font-size: 1.8rem;
        color: ${cores.white};
    }
`;

export const Banner = styled.div`
    justify-content: space-between;
    align-items: center;
    padding: 6rem 4rem;
    color: ${cores.white};
    background-image: url(${background});
    background-size: cover;
    background-position: center;    
    background-repeat: no-repeat;

    h3 {
    font-size: 36px;
    font-weight: 200;
    width: 39rem;

        b{
            font-weight: 600;
        }
        
        @media (max-width: 720px) {
            font-size: 24px;
            width: 20.5rem;
            text-align: center;
        }
    }

    @media (max-width: 720px) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
`;

export const OptionsBanner = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-top: 2rem;
    
    p {
        text-decoration: underline;

        a {
            color: ${cores.white};
            text-decoration: none;
        }

        @media (max-width: 720px) {
            margin-top: 2rem;
            text-align: center;
        }
    }

    @media (max-width: 720px) {
        display: inline-block;
    }
`

export const ButtonSecondary = styled.button`
    padding: 1rem 1.5rem;
    border-radius: 2rem;
    border: 1px solid ${cores.white};
    background-color: transparent;
    font-weight: 600;
    font-size: 16px;
    color: ${cores.white};

    a {
        color: ${cores.white};
        text-decoration: none;
    }
`

export const Partners = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    background-color: ${cores.secondaryGray400};

    img {
        margin: 0 40px;
    }

    img:not(:last-child) {
            @media (max-width: 720px) {
                margin-bottom: 2rem;
        }
        }

    @media (max-width: 720px) {
        display: none;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`

export const OurHistory = styled.div`
    background-color: ${cores.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8rem 0rem;
    text-align: center;
    gap: 2rem;

    h2 {
        font-size: 32px;
        color: #222;
        font-weight: 400;

        span {
            font-weight: 700;
        }
    }
    
    p {

        max-width: 700px;
        color: ${cores.secondaryGray800}
    }

    @media (max-width: 720px) {
        padding: 6rem 3rem;
    }
`

export const TagTitle = styled.div`
    padding: 0.6rem 2rem;
    width: auto;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: ${cores.primaryBlue};
    background-color: ${cores.secondaryGray400};
    border-radius: 2rem;
    
    p {
        font-weight: 500;
    }
`

export const TagTitleDark = styled.div`
    padding: 0.6rem 2rem;
    width: 18rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: ${cores.white};
    background-color: #1D1936;
    border-radius: 2rem;
    
    p {
        font-weight: 500;
    }
`

export const Metrics = styled.div`
    background-color: ${cores.primaryBlue};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8rem 0rem;
    text-align: center;
    gap: 2rem;

    h2 {
        font-size: 32px;
        color: ${cores.white};
        font-weight: 400;

        span {
            font-weight: 700;
        }
    }
    
    p {

        max-width: 700px;
        color: ${cores.white}
    }

    @media (max-width: 720px) {
        padding: 6rem 3rem;
    }
`

export const Cards = styled.div`
    display: flex;
    align-items: center;
    padding: 2rem 0rem;
    text-align: center;
    gap: 2rem;

    @media (max-width: 720px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`

export const CardMetrics = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;

    h3 {
        color: ${cores.white};
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
    }
    
    p {
        font-size: 16px;
        font-weight: 200;
        max-width: 270px;

        span {
            font-weight: 500;
        }

        @media (max-width: 720px) {
            text-align: center;
        }
    }

    @media (max-width: 720px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`

export const Production = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 8rem;

    @media (max-width: 720px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 6rem 3rem;
    }
`

export const ProductionDescription = styled.div`
        max-width: 300px;
        
        h2 {
            font-size: 32px;
            color: ${cores.primaryBlue};
            font-weight: 400;
            margin-top: 2rem;
            margin-bottom: 2rem;

            span {
                font-weight: 700;
            }
        }
        
        p {
            color: ${cores.secondaryGray800};
        }
`

export const Arrow = styled.img`
    margin-top: 2rem;

    @media (max-width: 720px) {
        display: none;
    }
`

export const ProductData = styled.div`
    min-width: 450px;
`

export const Data = styled.div`
    display: flex;
    margin-bottom: 2rem;

    @media (max-width: 720px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 3rem;
    }
`

export const DataLine = styled.img`
    width: 0.2rem;
    height: 8rem;
    margin-right: 2rem;

    @media (max-width: 720px) {
        display: none;
    }
`

export const DataContent = styled.img`
    width: 16rem;
`