import styled from 'styled-components';


// Container principal
export const FooterContainer = styled.footer`
    background-color: #fff;
    padding: 20px 0;
    border-top: 1px solid #ddd;
`;

// Conteúdo do Footer
export const FooterContent = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    @media (max-width: 720px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0rem 4rem;
    }
`;

// Logotipo
export const Logo = styled.div`
    img {
        width: 150px;

        @media (max-width: 720px) {
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
    }
`;

// Informações de contato
export const ContactInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    div {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        
        svg {
        margin-right: 10px;
        }
        
        a {
        text-decoration: none;
        color: #000;
        }
    }
`;

export const GroupInfo = styled.div`
    display: flex;
    gap: 2rem;
`

export const Info = styled.div`
    display: flex;
    gap: 0.8rem;
`

// Rodapé inferior com direitos autorais
export const FooterBottom = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    border-top: 1px solid #ddd;
    padding: 1rem 4rem;
    font-size: 12px;
    color: #666;
    justify-content: space-between;

    @media (max-width: 720px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 1rem;
    }
`;

// Links de navegação
export const NavLinks = styled.nav`
    display: flex;
    justify-content: center;

    a {
        text-decoration: none;
        color: #000;
        margin-right: 20px;
        font-size: 14px;
    }

    a:last-child {
        margin-right: 0;
    }

    @media (max-width: 720px) {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
`;