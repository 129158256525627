import React from 'react';
import { Container, Banner, ButtonWhatsApp } from './styles';
import whatsapp from "../../assets/Icons/whatsapp.svg"

function BannerContact() {
    return (
    <Container>
        <Banner>
            <h2>Entre em contato conosco!</h2>
            <p>Clique no botão abaixo para ser redirecionado ao nosso whatsApp</p>
            <a href="https://api.whatsapp.com/send/?phone=5511999665540" target="_blank" rel="noopener noreferrer">
                <ButtonWhatsApp>
                        <img src={whatsapp} alt="Ícone do WhatsApp" />
                        <p>Clique aqui!</p>
                </ButtonWhatsApp>
            </a>
        </Banner>
    </Container>
    );
}

export default BannerContact;