import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import {HeaderContainer, Nav, ButtonPrimary, ButtonPrimaryDark, Banner, OptionsBanner, ButtonSecondary, Partners, OurHistory, TagTitle, TagTitleDark, Metrics, Cards, CardMetrics, Production, ProductionDescription, Arrow, ProductData, Data, DataContent, DataLine, HamburgerIcon} from './styles';
// import { Footer, HomeSection, AboutSection, ProductsSection, ContactSection } from './Components';
import logotipo from '../assets/logotipo.png'
import partnerOne from '../assets/partnerOne.png'
import partnerTwo from '../assets/partnerTwo.png'
import partnerThree from '../assets/partnerThree.png'
import people from '../assets/Icons/people.svg'
import cup from '../assets/Icons/cup.svg'
import check from '../assets/Icons/check.svg'
import star from '../assets/Icons/star.svg'
import timer from '../assets/Icons/timer.svg'
import diagram from '../assets/Icons/diagram.svg'
import arrow from '../assets/Icons/Arrow.svg'
import linedata from '../assets/linedata.svg'
import dataTwo from '../assets/DataTwo.svg'
import dataOne from '../assets/dataOne.svg'
import BannerContact from '../Components/BannerContact';
import Footer from '../Components/Footer';
import { FaBars, FaTimes } from 'react-icons/fa'; // Ícones para o menu


function Home() {

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
    <>
        <HeaderContainer>
            <HamburgerIcon onClick={toggleMenu}>
                {menuOpen ? <FaTimes /> : <FaBars />}
            </HamburgerIcon>

            <img src={logotipo} alt="Logotipo NMA" />

            <Nav open={menuOpen}>
                <ul>
                <li><Link to="/">Início</Link></li>
                <li><Link to="/aboutUs">Quem somos</Link></li>
                <li><Link to="/products">Nossos produtos</Link></li>
                </ul>
            </Nav>

            <ButtonPrimary>
                <a href="https://api.whatsapp.com/send/?phone=5511999665540" target="_blank" rel="noopener noreferrer">
                    Fale conosco
                </a>
            </ButtonPrimary>
        </HeaderContainer>

        <Banner>
            <h3>
            Somos uma empresa <b>metalurgica</b> especializada em conformação de arame redondo ou laminado para o setor ferroviário.
            </h3>
            <OptionsBanner>
                <ButtonSecondary>
                    <a href="https://api.whatsapp.com/send/?phone=5511999665540" target="_blank" rel="noopener noreferrer">
                        Entre em contato conosco
                    </a>
                </ButtonSecondary>
                <p><Link to="/aboutUs">Saiba mais</Link></p>
            </OptionsBanner>
        </Banner>

        <Partners>
            <img src={partnerOne} alt="Logotipo TecnoArames" />
            <img src={partnerTwo} alt="Logotipo Petrotemp" />
            <img src={partnerThree} alt="Logotipo NS Metal" />
        </Partners>

        <OurHistory>
            <TagTitle>
                <img src={people} alt="Ícone ilustrando pessoas" />
                <p>Nossa história</p>
            </TagTitle>
            <h2>Anos de tradição <span>& construção.</span></h2>
            <p>A NMA Indústria nasceu do desejo do coração dos primos Ricardo e Thiago que desde 2006 tinham um sonho de empreenderem na metalurgia, porém trilharam caminhos distintos. Em 2022, uma nova oportunidade surge e então, a NMA surge como uma nova proposta revolucionária de mercado.</p>
            <ButtonPrimaryDark>
                <Link to="/aboutUs">
                    Saiba mais sobre
                </Link>
            </ButtonPrimaryDark>
        </OurHistory>

        <Metrics>
            <TagTitleDark>
                <img src={cup} alt="Ícone ilustrando um troféu" />
                <p>Métricas de qualidade</p>
            </TagTitleDark>
            <h2>Quem conhece, <span>assegura.</span></h2>
            <Cards>
                <CardMetrics>
                    <img src={check} alt="Ícone ilustrando um checkbox" />
                    <h3>Validação Qualytest</h3>
                    <p>Certificando que estamos fabricando dentro dos padrões exigidos conforme especificação e atendimento às normas.</p>
                </CardMetrics>
                <CardMetrics>
                    <img src={star} alt="Ícone ilustrando uma estrela se destacando" />
                    <h3>Experiência garantida</h3>
                    <p>Uma equipe de sócios especializada em metalúrgicas há <span>mais de 18 anos</span>, com uma experiência assertiva.</p>
                </CardMetrics>
                <CardMetrics>
                    <img src={timer} alt="Ícone ilustrando um timer" />
                    <h3>Atendimento ágil</h3>
                    <p>Nós acreditamos que para uma parceria de sucesso, nossos atendimentos devem ser ágeis e objetivos.</p>
                </CardMetrics>
            </Cards>
        </Metrics>

        <Production>
            <ProductionDescription>
                <TagTitle>
                    <img src={diagram} alt="Ícone ilustrando pessoas" />
                    <p>Métricas de produção</p>
                </TagTitle>
                <h2>Nossa capacidade <span>produtiva</span></h2>
                <p>Confira algumas métricas de produção da NMA, considerando principalmente a capacidade de produção.</p>
                <Arrow src={arrow} alt="Seta indicando o lado esquerdo" />
            </ProductionDescription>
            <ProductData>
                <Data>
                    <DataLine src={linedata} alt="Ícone de linha reta azul escuro" />
                    <DataContent src={dataOne} alt="Capacidade Produtiva Atual de 400 mil de peças ao mês" />
                </Data>
                <Data>
                    <DataLine src={linedata} alt="Ícone de linha reta azul escuro" />
                    <DataContent src={dataTwo} alt="Capacidade Produtiva Total de 1,2 milhão de peças ao mês" />
                </Data>
            </ProductData>
        </Production>

        <BannerContact />

        <Footer />
    </>
    );
}

export default Home;
