import styled from 'styled-components';
import { GlobalStyles, cores } from '../globalStyles.js';

export const TextTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    h3 {
        font-size: 36px;
        font-weight: 400;
        width: 39rem;

            b{
                font-weight: 600;
            }

            @media (max-width: 720px) {
                text-align: center;
                width: 24rem;
            }
    }

    @media (max-width: 720px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`

export const TitlePage = styled.div`
    display: flex;
    justify-content: space-between;
    padding-left: 8rem;
    padding-right: 2rem;

    @media (max-width: 720px) {
        padding: 0rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 2rem;
    }
`

export const TagTitle = styled.div`
    padding: 0.6rem 2rem;
    max-width: 15rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: ${cores.primaryBlue};
    background-color: ${cores.secondaryGray400};
    border-radius: 2rem;
    margin-bottom: 2rem;
    
    p {
        font-weight: 500;
    }
`

export const TagTitleDark = styled.div`
    padding: 0.6rem 2rem;
    width: 18rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: ${cores.white};
    background-color: #1D1936;
    border-radius: 2rem;

    img {
        width: 1.6rem;
    }
    
    p {
        font-weight: 500;
    }
`

export const ImageTitle = styled.div`
    img {
        width: 80%;

        @media (max-width: 720px) {
            display: none;
        }
    }
`

export const TextPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;
    padding: 4rem 0rem;

    h3 {
        font-size: 36px;
        font-weight: 400;
        width: 39rem;

        @media (max-width: 720px) {
            width: 20rem;
        }
    }

    p {
        color: ${cores.secondaryGray800};
        width: 48rem;

        @media (max-width: 720px) {
            width: 24rem;
            margin-bottom: 2rem;
        }
    }

    @media (max-width: 720px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0rem;
        margin-top: 3rem;
        gap: 1rem;
    }
`

export const BannerImage = styled.div`
    background-color: ${cores.secondaryGray400};
    display: flex;
    flex-direction: column;
    align-items: center;
    
    img {
        width: 60%;

        @media (max-width: 720px) {
            width: 100%;
        }
    }
`

export const TagAlert = styled.div`
    width: auto;
    background-color: ${cores.secondaryGray400};
    color: ${cores.primaryBlue};
    font-weight: 600;
    border-radius: 2rem;
    padding: 0.6rem 2rem;

    @media (max-width: 720px) {
            width: 60%;
    }
`

export const Grampos = styled.img`
    width: 40%;
    margin-top: 2rem;
    margin-bottom: 2rem;

    @media (max-width: 720px) {
            width: 80%;
    }
`