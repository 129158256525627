import React from 'react';
import HeaderLight from '../Components/HeaderLight/index.js';
import { BannerImage, Grampos, ImageTitle, TagAlert, TagTitle, TextPage, TextTitle, TitlePage } from './styles.js';
import people from '../assets/Icons/people.svg';
import imageproducts from '../assets/imageproducts.png';
import arruelas from '../assets/arruelas.png';
import grampos from '../assets/grampos.png';
import BannerContact from '../Components/BannerContact/index.js';
import Footer from '../Components/Footer/index.js';


function Products() {
    return(
        <>
            <HeaderLight />

            <TitlePage>
                <TextTitle>
                    <TagTitle>
                        <img src={people} alt="Ícone ilustrando pessoas" />
                        <p>Nossos produtos</p>
                    </TagTitle>
                    <h3>
                        <b>Nossos produtos</b><br />
                        & visões futuras<br />
                    </h3>
                </TextTitle>
                <ImageTitle>
                    <img src={imageproducts} alt="Imagem enfatizando os detalhes dos trilhos de uma ferrovia." />
                </ImageTitle>
            </TitlePage>

            <TextPage>
                <h3>Arruelas dupla de pressão Fe6</h3>
                <p>A arruela dupla de pressão é utilizada na montagem do conjunto tirefão – placa de apoio ferroviária, que são submetidos a grandes esforços, vibrações e variações de temperatura. Desta forma, a NMA garante materiais de qualidade, de acordo com o DNIT, observadas as normas técnicas brasileiras.</p>
            </TextPage>

            <BannerImage>
                <img src={arruelas} alt="Imagens de arruelas." />
            </BannerImage>

            <TextPage>
                <h3>Grampos de fixação</h3>
                <p>A fabricação de Grampos de fixação é um <b>plano de negócio em desenvolvimento</b>. Os grampos são peças metálicas, com características elásticas capazes de conter os esforços longitudinais e transversais, utilizados para fixação dos trilhos em dormentes ao longo de uma ferrovia.</p>
                <TagAlert>
                    Em desenvolvimento! Em breve estará disponível
                </TagAlert>
                <Grampos src={grampos} alt="Imagem de grampos de fixação." />
            </TextPage>

            <BannerContact />

            <Footer />
        </>
    )
}

export default Products;