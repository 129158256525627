import React, { useState } from 'react';
import { ButtonPrimary, Container, Nav, HamburgerIcon } from './styles';
import logotipoDark from '../../assets/LogotipoDark.png';
import { FaBars, FaTimes } from 'react-icons/fa'; // Ícones para o menu
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';


function HeaderLight() {

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <Container>
            <HamburgerIcon onClick={toggleMenu}>
                {menuOpen ? <FaTimes /> : <FaBars />}
            </HamburgerIcon>
            
            <img src={logotipoDark} alt="Logotipo NMA" />

            <Nav open={menuOpen}>
                <ul>
                <li><Link to="/">Início</Link></li>
                <li><Link to="/aboutUs">Quem somos</Link></li>
                <li><Link to="/products">Nossos produtos</Link></li>
                </ul>
            </Nav>
            <ButtonPrimary>
                <a href="https://api.whatsapp.com/send/?phone=5511999665540" target="_blank" rel="noopener noreferrer">
                    Fale conosco
                </a>
            </ButtonPrimary>
        </Container>
    )
}

export default HeaderLight;