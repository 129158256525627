import styled from 'styled-components';
import { GlobalStyles, cores } from '../../globalStyles';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 4rem;
    background-color: ${cores.white};
    color: ${cores.primaryBlue};

    img {
        width: 8rem;
    }

    @media (max-width: 720px) {
        padding: 1rem 2rem;

        align-items: center;

        img {
            width: 6rem; 
        }
    }
`

export const Nav = styled.nav`
    ul {
        list-style: none;
        display: flex;
    }

    li {
        margin: 0 15px;
    }

    a {
        color: ${cores.primaryBlue};
        text-decoration: none;
        &:hover {
        text-decoration: underline;
        }
    }

    @media (max-width: 720px) {
        display: ${({ open }) => (open ? 'block' : 'none')}; 
        ul {
            flex-direction: column;
            align-items: center;
            padding: 0;
        }

        li {
            margin: 10px 0;
        }

        position: absolute;
        top: 80px; 
        left: 0;
        right: 0;
        background-color: ${cores.white};
        padding: 1rem;
        box-shadow: 0 8px 16px rgba(0,0,0,0.1);
        border-radius: 8px;
    }
`;

export const ButtonPrimary = styled.button`
    padding: 1rem 1.5rem;
    border-radius: 2rem;
    border: none;
    font-weight: bold;
    background-color: ${cores.primaryBlue};
    color: ${cores.white};

    a {
        color: ${cores.white};
        text-decoration: none;
    }

    @media (max-width: 720px) {
        padding: 0.8rem 1.2rem;
        width: 40%;
        max-width: 300px;
    }
`;

export const HamburgerIcon = styled.div`
    display: none;

    @media (max-width: 720px) {
        display: block;
        cursor: pointer;
        font-size: 1.8rem;
        color: ${cores.primaryBlue};
    }
`;