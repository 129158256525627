import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { ContactInfo, FooterBottom, FooterContainer, FooterContent, GroupInfo, Info, Logo, NavLinks,  } from './styles';
import logotipoDark from '../../assets/LogotipoDark.png'
import call from '../../assets/Icons/call.svg'
import location from '../../assets/Icons/location.svg'
import sms from '../../assets/Icons/sms.svg'


function Footer() {
    return (
        <FooterContainer>
            <FooterContent>
                {/* Seção do logotipo */}
                <Logo>
                    <Link to="/">
                        <img src={logotipoDark} alt="Logo NMA Indústria" />
                    </Link>
                </Logo>

                {/* Informações de contato */}
                <ContactInfo>
                    <Info>
                        <img src={location} alt="Ícone de localização" />
                        <span><a href="https://maps.app.goo.gl/vRxQFvZR4RVpP8mw6" target="_blank" rel="noopener noreferrer">R. São Vicente Ferrer, 785 - Guarulhos - SP, 07175-090</a></span>
                    </Info>
                    <Info>
                        <img src={sms} alt="Ícone de e-mail" />
                        <a href="mailto:comercial@nmaindustria.com.br">comercial@nmaindustria.com.br</a>
                    </Info>
                    <GroupInfo>
                        <Info>
                            <img src={call} alt="Ícone de telefone" />
                            <a href="https://api.whatsapp.com/send/?phone=5511999665540">(11) 99966-5540</a>
                        </Info>
                        <Info>
                            <img src={call} alt="Outro ícone de telefone" />
                            <a href="https://api.whatsapp.com/send/?phone=5511989336563">(11) 98933-6563</a>
                        </Info>
                    </GroupInfo>
                </ContactInfo>
            </FooterContent>

            {/* Rodapé inferior */}
            <FooterBottom>
                <NavLinks>
                    <Link to="/">Home</Link>
                    <Link to="/aboutUs">Quem Somos</Link>
                    <Link to="/products">Nossos Produtos</Link>
                </NavLinks>

                <p>© NMA Indústria. Todos os direitos reservados.</p>
            </FooterBottom>
        </FooterContainer>
    );
}

export default Footer;
