import React from 'react';
import { Container, TitlePage, TagTitle, TextTitle, ImageTitle, TextPage, BannerImages, TagTitleDark, BannerTimeline, Timeline } from './styles';
import HeaderLight from '../Components/HeaderLight/index.js';
import people from '../assets/Icons/people.svg';
import timer from '../assets/Icons/timer.svg';
import imagepartners from '../assets/imagepartners.png';
import bannerOne from '../assets/bannerOne.png';
import bannerTwo from '../assets/bannerTwo.png';
import timeline from '../assets/timeline.svg';
import BannerContact from '../Components/BannerContact/index.js';
import Footer from '../Components/Footer/index.js';


function AboutUs() {
    return (
        <>
            <HeaderLight />

            <TitlePage>
                <TextTitle>
                    <TagTitle>
                        <img src={people} alt="Ícone ilustrando pessoas" />
                        <p>Quem somos</p>
                    </TagTitle>
                    <h3>
                        Anos de tradição<br />
                        <b>& construção.<br />
                        Conheça nossa história</b>
                    </h3>
                </TextTitle>
                <ImageTitle>
                    <img src={imagepartners} alt="Imagem de duas pessoas dando as mãos, simulando uma parceria de negócios" />
                </ImageTitle>
            </TitlePage>

            <TextPage>
                <div>
                A NMA Indústria nasceu do desejo do coração dos primos Ricardo e Thiago que desde 2006 tinham um sonho de empreenderem na metalurgia, porém trilharam caminhos distintos. Ricardo continuou na metalurgia e criou a NSMetal, fabrica de molas em Guarulhos. Thiago, passou por algumas empresas, nacionais e Multinacionais no setor de telecomunicações, e em2013 iniciou a SAT Telecomunicações em Guarulhos.<br />
                <br />
                Em 2022, Ricardo teve a oportunidade de voltar a INDÚSTRIA DE MOLASAÇOS, empresa que foi seu primeiro emprego e teve a oportunidade de conhecer tudo sobre metalurgia e processos de qualidade, dessa vez na condição de Adquirir algumas maquinas para a NS Metal. Foi então que observou a oportunidade de Adquirir as linhas de produções de Arruela Dupla ferroviária e grampos. Imediatamente convidou Thiago para o negócio, que de pronto aceitou e em Fevereiro de 2022, compraram as linhas.
                </div>
                <div>
                Thiago e Ricardo desenvolveram o Business Plan e decidiram então que precisavam de um sócio do mercado Ferroviário com os mesmos princípios. Após conversas com algumas pessoas chaves, convidaram o Antonio Carlos, proprietário da empresa Trilhofer, para o negocio e aceitou. Em Janeiro de2023, iniciamos o retrofit das maquinas e a operação no bairro Vila nova Bonsucesso em Guarulhos, com ex funcionários da operação da Industria Molas Aço.<br/>
                <br />
                Em Abril/2023, fomos validados pelo Laboratório QUALYTEST, certificandoque estamos fabricando dentro dos padrões exigidos conformeespecificação e atendimento as normas (EPS-ENG-2041-01-00 - ARRUELADUPLA DE PRESSÃO) - MRS.
                </div>
            </TextPage>

            <BannerImages>
                <img src={bannerOne} alt="Imagem mostrando um pôr do sol acontecendo em uma ferrovia." />
                <img src={bannerTwo} alt="Imagem enfatizando os trilhos em uma ferrovia." />
            </BannerImages>

            <BannerTimeline>
                <TagTitleDark>
                    <img src={timer} alt="Ícone ilustrando pessoas" />
                    <p>Projeto NMA Indústria</p>
                </TagTitleDark>
                <h3>Timeline de construção</h3>
                <Timeline src={timeline} alt="Timeline mostrando eventos importantes em ordem cronológica." />
            </BannerTimeline>

            <BannerContact />

            <Footer />
        </>
    )
}

export default AboutUs;