import styled from 'styled-components';
import { GlobalStyles, cores } from '../../globalStyles.js';

export const Container = styled.div`
    padding: 2rem;
`

export const Banner = styled.div`
    background-color: ${cores.primaryBlue};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 0rem;
    text-align: center;
    gap: 1rem;
    border-radius: 1rem;
    color: ${cores.white};

    a {
        text-decoration: none;
        cursor: pointer;
    }

    h2 {
    font-weight: 400;
    }

    @media (max-width: 720px) {
        padding: 4rem 4rem;
    }
`

export const ButtonWhatsApp = styled.button`
    padding: 0.5rem 1.5rem;
    border-radius: 3rem;
    border: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: ${cores.secondaryGreen200};
    color: ${cores.secondaryGreen600};
    margin-top: 1rem;
    cursor: pointer;

    p {
        font-size: 18px;
    }
`