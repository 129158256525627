// src/GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

// Definir um objeto com suas cores
export const cores = {
    white: '#ffffff',
    black: '#000000',
    primaryBlue: '#040020',
    secondaryGreen200: '#AFF3BE',
    secondaryGreen600: '#527359',
    secondaryGray400: '#ECECEC',
    secondaryGray800: '#525252',
};

// Estilos globais
export const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        box-sizing: border-box;
        font-family: 'Montserrat', sans-serif;
    }

    body {
        background-color: ${cores.white};
        font-family: 'Montserrat', sans-serif;
    }
`;
