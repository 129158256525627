import styled from 'styled-components';
import { GlobalStyles, cores } from '../globalStyles.js';

export const Container = styled.div``

export const TextTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    h3 {
        font-size: 36px;
        font-weight: 400;
        width: 39rem;

            b{
                font-weight: 600;
            }
            
            @media (max-width: 720px) {
                text-align: center;
                width: 24rem;
            }
    }

    @media (max-width: 720px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`

export const TitlePage = styled.div`
    display: flex;
    justify-content: space-between;
    padding-left: 8rem;
    padding-right: 2rem;

    @media (max-width: 720px) {
        padding: 0rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 2rem;
    }
`

export const TagTitle = styled.div`
    padding: 0.6rem 2rem;
    max-width: 14rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: ${cores.primaryBlue};
    background-color: ${cores.secondaryGray400};
    border-radius: 2rem;
    margin-bottom: 2rem;
    
    p {
        font-weight: 500;
    }
`

export const TagTitleDark = styled.div`
    padding: 0.6rem 2rem;
    width: 18rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: ${cores.white};
    background-color: #1D1936;
    border-radius: 2rem;

    img {
        width: 1.6rem;
    }
    
    p {
        font-weight: 500;
    }
`

export const ImageTitle = styled.div`
    img {
        width: 80%;

        @media (max-width: 720px) {
            display: none;
        }
    }
`

export const TextPage = styled.div`
    display: flex;
    gap: 4rem;
    padding: 4rem 8rem;
    text-align: justify;

    div {
        max-width: 50%;
        color: ${cores.secondaryGray800};

        @media (max-width: 720px) {
            max-width: 80%;
        }
    }

    @media (max-width: 720px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0rem;
        margin-top: 3rem;
        gap: 1rem;
    }
`

export const BannerImages = styled.div`
    display: flex;
    margin-top: 4rem;

    img{
        width: 50%;

        @media (max-width: 720px) {
            width: 100%;
        }
    }

    @media (max-width: 720px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`

export const BannerTimeline = styled.div`
    background-color: ${cores.primaryBlue};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding: 4rem 0rem;

    h3 {
        font-size: 36px;
        font-weight: 400;
        width: 39rem;
        text-align: center;
        color: ${cores.white};

        @media (max-width: 720px) {
            width: 20rem;
        }
    }
`

export const Timeline = styled.img`
    width: 80%;

    @media (max-width: 720px) {
        width: 140%;
    }
`